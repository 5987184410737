var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"de-duplication",class:{ navigation: _vm.NAVIGATION_ENABLED }},[_c('div',{staticClass:"left-col"},[_c('h3',{staticClass:"section-header text-xl semibold"},[_vm._v(" "+_vm._s(_setup.i18n.t("page.deduplication.profileToIdentificate"))+" ")]),(_vm.currentProfileToDeduplicate)?_c(_setup.IdentificationWorkerColumn,{attrs:{"profileToDeDuplicate":_vm.currentProfileToDeduplicate,"pagination":{ activatePagination: false, currentIndex: 0, maxElements: 0 },"fields":[
                {
                    label: _setup.i18n.t('form.email').toString(),
                    value: _vm.currentProfileToDeduplicate.email || '-',
                    isSameValue: false,
                },
                {
                    label: _setup.i18n.t('form.phone').toString(),
                    value: _vm.convertPhone(_vm.currentProfileToDeduplicate.phone) || '-',
                    isSameValue: false,
                },
            ]},scopedSlots:_vm._u([{key:"actionContainer",fn:function(){return [_c('div',{staticClass:"action-button-container"},[_c(_setup.ActionButton,{staticClass:"primary",attrs:{"disabled":_vm.disabler.addToMyAts},on:{"onClick":function($event){return _vm.onToggleModal('ModalDeduplicateConfirmUnique')}}},[_vm._v(" "+_vm._s(_setup.i18n.t("page.deduplication.addToMyResumeLibrary"))+" ")]),_c(_setup.ActionButton,{staticClass:"primary",attrs:{"disabled":_vm.disabler.addToMyAts},on:{"onClick":_vm.resetProfileToDeDuplicatesIndex}},[_vm._v(" "+_vm._s(_setup.i18n.t("global.cancel"))+" ")])],1)]},proxy:true}],null,false,957033383)}):_vm._e()],1),_c('div',{staticClass:"right-col"},[_c('h3',{staticClass:"section-header text-xl semibold"},[_vm._v(" "+_vm._s(_setup.i18n.t("page.deduplication.nbDuplicatedFound." + (_vm.identificationResults.length <= 1 ? "singular" : "plural"), { nb: _vm.identificationResults.length, }))+" ")]),(!_vm.showIdentificationResults)?_c('div',{staticClass:"right-col-content"},[(_vm.loading)?_c('div',{staticClass:"lottie-loader"},[_c(_setup.LottieFile,{attrs:{"jsonLottieFile":_setup.loaderSearchDuplicatedLottieAnnimation}}),_c('p',{staticClass:"text-lg semibold"},[_vm._v(_vm._s(_setup.i18n.t("page.deduplication.loadingDuplicated")))])],1):_vm._e(),_vm._l((_vm.identificationResults),function(duplicatedProfile,duplicatedIndex){return (!_vm.loading)?_c('div',{key:duplicatedIndex,on:{"click":function($event){$event.stopPropagation();return _vm.onShowDuplicatedDetails(duplicatedIndex)}}},[_c(_setup.IdentificationWorkerCard,{attrs:{"displayOrigin":true,"displayValidity":true,"fields":[
                        {
                            label: 'email',
                            value: duplicatedProfile.email || '-',
                            isSameValue: duplicatedProfile.email === _vm.currentProfileToDeduplicate?.email,
                        },
                        {
                            label: 'phone',
                            value: _vm.convertPhone(duplicatedProfile.phone),
                            isSameValue: _vm.convertPhone(duplicatedProfile.phone) === _vm.convertPhone(_vm.currentProfileToDeduplicate?.phone ?? ''),
                        },
                    ],"identity":{
                        firstname: duplicatedProfile.firstname,
                        lastname: duplicatedProfile.lastname,
                        isValid: _vm.isSameFirstnameAndLastname(_vm.currentProfileToDeduplicate, duplicatedProfile),
                        databaseOrigin: duplicatedProfile.databaseOrigin,
                        picture: duplicatedProfile.picture,
                    },"mode":"light","agencies":duplicatedProfile.agencies},scopedSlots:_vm._u([{key:"action-container",fn:function(){return [_c('div',{on:{"click":function($event){$event.stopPropagation();return _vm.onToggleModal('ModalDeduplicateConfirmMerge')}}},[_c(_setup.ActionButton,[_c('NewIcon',{attrs:{"icon":"design-system-git-merge"}}),_vm._v(" "+_vm._s(_setup.i18n.t("page.deduplication.merge"))+" ")],1)],1)]},proxy:true}],null,true)})],1):_vm._e()})],2):_vm._e(),(_vm.showIdentificationResults && _vm.currentIdentificationResult)?_c(_setup.IdentificationWorkerColumn,{attrs:{"profileToDeDuplicate":_vm.currentIdentificationResult,"pagination":{
                activatePagination: true,
                maxElements: _vm.identificationResults.length,
                currentIndex: _vm.identificationResultsIndex,
            },"fields":_vm.currentIdentificationResultFields,"verifyFields":true,"displayOrigin":true,"isValidIdentity":_vm.isSameFirstnameAndLastname(_vm.currentProfileToDeduplicate, _vm.currentIdentificationResult)},on:{"changeProfile":(index) => _vm.setIdentificationResultsIndex(index),"backButton":() => _vm.setShowIdentificationResults(false)},scopedSlots:_vm._u([{key:"actionContainer",fn:function(){return [_c(_setup.ActionButton,{staticClass:"primary",attrs:{"disabled":_vm.disabler.addToMyAts},on:{"onClick":function($event){return _vm.onToggleModal('ModalDeduplicateConfirmMerge')}}},[_c('NewIcon',{attrs:{"icon":"design-system-git-merge"}}),_vm._v(" "+_vm._s(_setup.i18n.t("page.deduplication.merge"))+" ")],1)]},proxy:true}],null,false,2913408522)}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }